.eyecatcher {
	// margin: 0 30px;
	position: relative;

	.container-fluid {
		padding: 0 30px !important;
		@include media-breakpoint-up(sm) {
			padding: 0 60px !important;
		}
	}

	&.large {
		min-height: $carousel-min_height;

		@include media-breakpoint-up(lg) {
			min-height: $carousel-min_height_lg;
		}

		.owl-carousel {
			min-height: $carousel-min_height;

			@include media-breakpoint-up(lg) {
				min-height: $carousel-min_height_lg;
			}

			.owl-stage-outer {
				.owl-item {
					.item {
						min-height: $carousel-min_height;

						@include media-breakpoint-up(lg) {
							min-height: $carousel-min_height_lg;
						}
						.owl-btn {
							background: $secondary;
							border-color: $secondary;
							&:hover {
								background: darken($secondary, 10%);
								border-color: darken($secondary, 10%);
								color: $white;
							}
						}
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			.first,
			.second {
				z-index: 900;
				text-align: center;
				font-size: 26px !important;
				left: 0 !important;
				top: 50% !important;
				transform: translateY(-50%) !important;
				max-width: 98% !important;
			}
			.first {
				transform: translateY(calc(-100% - 1rem)) !important;
			}
			.owl-nav {
				display: none;
			}
			.owl-caption,
			.owl-caption-holder {
				min-height: 100% !important;
				margin-top: calc(60px + 6vh);
				text-align: center;
			}
			.owl-btn {
				margin: auto;

			}
		}
	}

	.eyecatcher-container {
		.container-holder {
			position: relative;

			.owl-carousel {
				.owl-stage-outer {
					border-radius: 20px;

					.owl-stage {
						.owl-item {
							.item {
								position: relative;

								&::before {
									content: "";
									position: absolute;
									background: linear-gradient(
										180deg,
										transparent,
										#16140560
									);
									top: 0;
									left: 0;
									height: 100%;
									width: 100%;
									opacity: 0.6;
								}
							}
						}
					}
				}
			}

			.first,
			.second {
				position: absolute;
				left: 5%;
				right: 0;
				top: 50px;
				z-index: 2;
				color: $white;
				font-size: $font-size-base * 1.625;
				font-family: $font-playball;
				font-weight: $font-weight-regular;
				@include media-breakpoint-up(sm) {
					font-size: $h1-font-size;
				}
			}

			.second {
				top: 80px;
				font-size: $h2-font-size;
				left: 15%;
				@include media-breakpoint-up(sm) {
					top: 110px;
					font-size: $font-size-base * 5;
				}
			}
		}
	}
}

&.home {
	.eyecatcher {
		&::after {
			background: url("/images/light-green-top-curve.svg") no-repeat
				top/100%;
			content: "";
			position: absolute;
			width: 100%;
			height: 270px;
			bottom: -45px;
			z-index: 0;
			right: 0;
			@include media-breakpoint-down(sm) {
				background-position: bottom !important;
				bottom: -1px;
			}
			@include media-breakpoint-up(sm) {
				bottom: -220px;
			}
			@include media-breakpoint-up(xl) {
				bottom: -35px;
			}
		}
	}
}

&:not(.home) {
	.eyecatcher {
		&:after {
			content: "";
			background: #fff;
			position: absolute;
			left: 0;
			width: 100%;
			height: 130px;
			top: -10px;
		}
	}
}
