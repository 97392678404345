.main {
	.overview-wrapper {
		position: relative;
		height: 100%;
		width: 100%;
		z-index: 0;

		.container {

		}

		.blog_filter {
			display: none;
		}

		h1 {
			font-family: $font-playball;
			font-size: $h1-font-size;
			font-weight: $font-weight-regular;
			color: $mineralgreen;

			& + p {
				font-family: $font-family-noto;
				font-size: $font-size-base;
				color: $mineralgreen;
				font-weight: $font-weight-regular;
				margin-bottom: 32px;
			}
		}
	}

	.overview-wrapper, .content.info {
		.page_block {
			&.grid {
				.item {
					margin-bottom: 40px;
					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}

					.card {
						border: none;
						border-radius: 0;
						background: transparent;

						.card-image {
							background: transparent;

							.card-image-link {
								.card-img {
									border-top-left-radius: 10px;
									border-top-right-radius: 10px;
								}
							}
						}

						.card-body {
							background: $catcus;
							border-bottom-left-radius: 10px;
							border-bottom-right-radius: 10px;
							padding: 28px;

							.card-caption {
								.card-title {
									font-family: $font-playball;
									font-size: $font-size-base * 2.5;
									font-weight: $font-weight-regular;
									color: $white;
								}

								.card-subtitle {
									color: $white;
									font-family: $font-family-noto;
								}

								.card-description {
									min-height: 100%;

									&::after {
										display: none !important;
									}

									.card-description-content {
										font-family: $font-family-noto;
										font-size: $font-size-base;
										font-weight: $font-weight-regular;
										color: $white;
										height: auto;

										p {
											color: $white;
										}
									}

									.card-prices {
										color: $white;
									}

									.list {
										&.list-icons {
											.list-item {
												color: $white;
											}
										}
									}
								}
							}

							.card-buttons {
								margin: 40px 0 10px 0;

								.card-btn {
									border-radius: 5px;
									background: $primary;
									padding: 12px 40px 12px 20px;
									font-family: $font-ibm-plex;
									font-size: $font-size-base_sm !important;
									font-weight: 700;
									position: relative;
									color: $white;
									margin-top: 20px;
									display: inline-block;

									&:hover {
										background: $white;
										border-color: $primary;
										color: $primary;
									}

									&::before {
										content: "\f30b";
										font-family: $font-awesome;
										font-size: $h4-font-size;
										position: absolute;
										top: 50%;
										transform: translateY(-50%);
										width: 20px;
										height: auto;
										display: block;
										right: 10px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.accommodation_overview {
	.card-description {
		.list-item {
			display: flex;
		}

		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 1.5rem;
			height: 100%;
		}
	}

	.card-description-content {
		display: none;
	}
}
