/* ==========================================================================
   body
   ========================================================================== */
   // $body-padding: 84px 0 0 0;
   // $body-sticky-padding: 84px 0 0 0;
   

   $box-shadow: rgba($black, 0.16);
   $link-1: #044389;

   /* ==========================================================================
   owl-carousel
   ========================================================================== */
$carousel-min_height: 40vh !default;
$carousel-min_height_lg: 70vh !default;
