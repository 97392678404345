.main {
	.content.detail {
		background: $clayash;
		position: relative;
		padding: 0;

		.container-default {
			.container-holder {
				h1 {
					font: {
						family: $font-playball;
						weight: $font-weight-regular;
					}
					position: relative;

					&::before {
						content: "\F30A";
						position: absolute;
						top: 50%;
						left: -30px;
						transform: translateY(-50%);
						font-family: $font-awesome;
						font-size: $h3-font-size;
						font-weight: 500;
						transition: 0.3s;
						width: 34px;
						height: 34px;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						color: $mineralgreen;
						@include media-breakpoint-up(xl) {
							background: $mineralgreen;
							color: $white;
							left: -60px;
						}
					}
				}

				p {
					max-width: 920px;
				}

				.details {
					padding-left: 0;

					.col-6 {
						padding-left: 0;
						@include media-breakpoint-down(sm) {
							@include make-col(12);
						}
					}

					p {
						.icon {
							color: $secondary;

							i {
								font-weight: 700;
							}
						}

						.label {
							margin-left: 10px;
							font-weight: 700;
						}
					}

					div {
						a {
							@extend .btn-highlight;
							font-size: $h4-font-size !important;
							font-weight: 500 !important;
							max-width: 300px;
						}
					}
				}
			}
		}
	}

	.content.info {
		height: 100%;
		width: 100%;
		margin-top: 30px;
		margin-bottom: 100px;
		@include media-breakpoint-up(sm) {
			padding-top: 143px;
			margin-top: 0;
		}

		.container-default {
			.container-holder {
				.accommodation_detail {
					padding-bottom: 0 !important;
					@include media-breakpoint-up(sm) {
						padding-bottom: 3rem !important;
					}
				}

				.info {

					.extra {
						font: {
							family: $font-ibm-plex;
						}
					}

					h1 {
						font: {
							family: $font-playball;
							weight: $font-weight-regular;
						}
					}

					h3 {
						font-size: $font-size-base * 1.25;
						margin-top: 30px;
					}

					.accommodation-properties {
						&__item {
							padding: 0;

							&::before {
								content: none;
							}

							.accommodation-properties__icon {
								color: $secondary;

								i {
									font-weight: 700;
								}
							}
						}
					}

					ul {
						list-style: none;
						padding: 0;

						li {
							position: relative;
							padding-left: 25px;
							margin-bottom: 10px;

							&::before {
								position: absolute;
								left: 0;
								top: 50%;
								content: "\F00C";
								transform: translateY(-50%);
								font-size: $font-size-base;
								font-family: $font-awesome;
								font-weight: 600 !important;
								color: $primary;
							}
						}
					}

					// a {
					//     @extend .btn-highlight;
					//     max-width: 300px;
					// }
					// commented out out because of showin links as buttons in
				}

				.info-extra {
					.gallery {
						&-item {
							.gallery-link {
								.gallery-img {
									border-radius: 4px;
								}
							}
						}
					}
				}

				.blog_detail, .service_detail {
					margin-bottom: 40px;
				}

				.grid {
					// .item {
					// 	@include media-breakpoint-up(lg) {
					// 		@include make-col(4);
					// 	}
					// }
				}
			}
		}
	}

	.content.overview {
		position: relative;
		padding: 0;

		.container-default {
			.container-holder {
				h1 {
					font-family: $font-playball;
					font-weight: $font-weight-regular;
				}
			}
		}

		.content {
			padding: 0.8rem 0 0.8rem;
		}
	}

	.content.overview-wrapper {
		padding: 0 0 0;
	}
}
