//--------------------Facility block--------------//

.block-1 {
	flex: 0 0 calc(100% - 350px);
	max-width: calc(100% - 350px);
	margin: 0;
	padding-right: 0 !important;
	padding-left: 0 !important;

	@include media-breakpoint-down(sm) {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.resource_image {
		position: relative;
		overflow: hidden;
		height: 100%;
		@include media-breakpoint-up(sm) {
			border-radius: 0;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0.5;
		}

		.img-fluid {
			object-fit: cover;
			width: 100%;
			max-height: 355px;
			border-radius: 0;
			height: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 0;
			}
		}
	}
}

.block-2 {
	background: $catcus;
	@include media-breakpoint-up(sm) {
		width: 350px;
		flex: 0 0 350px;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		margin-bottom: 0;
		padding-bottom: 20px;
	}
	@include media-breakpoint-down(sm) {
		flex: 0 0 100%;
		max-width: 100%;
	}

	h1 {
		font-family: $font-playball;
		color: $white;
		font-weight: 400;
		white-space: nowrap;
		margin-top: 0;
		margin-bottom: 40px;
		@include media-breakpoint-up(sm) {
			margin-top: 60px;
			margin-left: -140px;
			font-size: $font-size-base * 3;
		}
		@include media-breakpoint-down(sm) {
			margin-left: 0;
		}
	}

	p {
		color: $white;
		font-size: $font-size-base;
		font-family: $font-family-noto;
	}

	.link {
		font-size: $font-size-base;
		font-family: $font-ibm-plex;
		color: $white;
		position: relative;
		padding-right: 20px;
		text-decoration: underline;

		&::after {
			content: "\f30b";
			position: absolute;
			top: 50%;
			right: -10px;
			transform: translateY(-50%);
			font-family: $font-awesome;
			font-size: $h3-font-size;
			font-weight: 500;
			transition: 0.3s;
		}

		&:hover {
			&::after {
				right: -15px;
			}
		}
	}
}

.home-block {
	@include media-breakpoint-down(sm) {
		padding: 0 !important;
	}
}

.container-two-columns-block {
	@include media-breakpoint-down(sm) {
		padding: 0 40px !important;
	}
}

//-------Typography----------------------------//
h1 {
	font-family: Playball, cursive !important;
	font-weight: 400 !important;
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 2.25 !important;
	}
}

h2 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.375;
	}
}

&.accommodation_book_succes {
	.main {
		.content.overview {
			padding-bottom: 45px;
		}
	}
}

&:not(.home) {
	background: url(/images/repeat-grid.png) repeat 50% / cover;
}

.w3-vue-booking-wrapper
	.w3-daterangepicker
	.vc-popover-content-wrapper
	.vc-popover-content {
	@media (max-width: 767px) {
		margin: 0;
	}
}

&.default .main .overview-wrapper {
	z-index: 1!important;
}
