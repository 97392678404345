.header {
	/* Header-main
		   ========================================================================== */
	.header-socket {
		position: relative;
		background: $white;
		color: $white;
		z-index: 1000;
		.logo {
			max-width: 109px;
			margin-right: auto;
			margin-left: auto;
			@include media-breakpoint-up(lg) {
				max-width: 195px;
				margin-right: auto;
				margin-left: 25px;
			}

			img {
				@include media-breakpoint-down(md) {
					width: 109px;
				}
				@include media-breakpoint-down(xs) {
					margin-left: 1rem;
				}
			}
		}

		.column.navbar {
			padding: 24px 16px;

			.menu_toggle {
				position: relative;
				display: flex;
				@include media-breakpoint-down(sm) {
					left: 0;
				}

				&::before {
					content: "Menu";
					color: $primary;
					width: 100%;
					position: absolute;
					top: 99%;
					display: flex;
					margin-left: 8px;
					font-size: $font-size-base_sm;
					font-weight: 900;
					transition: 0.35s;
				}

				@include media-breakpoint-down(sm) {
					position: fixed;
					&::before {
						display: none;
					}
				}
				display: block !important; // !important needed to override base css
				background: url("/images/menu.svg");
				color: $white !important;
				border-color: transparent !important;
				width: 80px;
				height: 80px;
				border-radius: 50%;
				z-index: 2;

				&[aria-expanded="true"] {
					&.menu_toggle {
						&::before {
							opacity: 0;
							transform: translateY(-1rem);
							transition: 0.35s;
						}
					}

					.fa-bars {
						&::before {
							content: "\f00d";
						}
					}
				}

				.navbar-toggler-icon {
					background-image: none;
					width: 100%;
					height: auto;
				}
			}

			.page_block.menu {
				position: fixed;
				left: 0;
				top: 0;
				width: 100vw;
				transform: translateX(-100vw);
				height: 100%;
				background: $mineralgreen;
				padding: 5.625rem 2rem 2rem;
				transition: 0.25s;
				z-index: 1;
				@include media-breakpoint-up(md) {
					transform: translateX(-400px);
					width: 90%;
					max-width: 400px;
					padding: 5.625rem 2rem;
					&::before {
						content: "";
						display: none;
						opacity: 0;
						position: fixed;
						top: 0;
						left: 400px;
						right: 0;
						background: rgba($black, 0.5);
						bottom: 0;
						width: calc(100vw - 400px);
						transition: all 250ms;
					}
				}
				@include media-breakpoint-down(lg) {
					&.collapse {
						display: block;
					}
				}

				&.show {
					transform: translateX(0);

					&::before {
						display: inline-block;
						opacity: 1;
					}
				}

				&.side_panel_opened {
					.nav-item {
						&:not(.active_side_panel) {
							display: block !important; //!important was needed to override base styles
						}
					}
				}

				.side-panel-close {
					display: none;
				}

				.navbar-nav {
					width: 100%;
					flex-direction: column;
					justify-content: flex-start;
					height: 100%;
					overflow: auto;
					margin-top: 1.5rem;

					@include media-breakpoint-up(lg) {
						justify-content: center;
					}

					li:has(+ a.highlight) {
						background: red;
						border: 1px solid green;
					}

					> li {
						a {
							font-size: $font-size-base;
							font-weight: 600;
							color: $white;
							padding: 0.6rem 0;

							&:hover {
								text-decoration: none;

								&::after {
									margin-left: 15px;
									transition: 0.3ms ease-in-out;
								}
							}

							&.active {
								> a {
									color: $black;
									text-decoration: underline;
								}
							}

							&.highlight {
								background: #93b612;
								display: inline-flex;
								align-items: center;
								padding: 12px 40px;
								font-size: 0.875rem;
								text-transform: uppercase;
								font-family: "IBM Plex Mono", monospace;
								font-weight: 700;
								border-radius: 5px;
								color: #ffffff;
								text-decoration: none;
								position: absolute;
								top: 50px;
								left: 110px;
								transition: 0.3s;
							}
						}

						&:not(:last-child) a:not(.highlight) {
							border-bottom: 1px solid $white;
						}

						.dropdown-toggle {
							&::after {
								font-family: $font-awesome;
								font-size: $font-size-base_sm;
								font-weight: 400;
								border: none;
								position: absolute;
								top: 50%;
								margin-left: 10px;
								content: "\F178";
								transform: translateY(-50%);
								transition: 0.25s ease-in-out;
							}
						}

						&.nav-item.show {
							position: absolute;
							height: 100%;
							width: 100%;
							max-width: 400px;
							background: $mineralgreen;
							padding: 0 30px;
							left: 0;
							z-index: 1;

							.dropdown-toggle {
								display: none;
							}

							.nav-link {
								transition: none;
							}

							ul.dropdown-menu.show {
								margin-top: 1.5rem;
								position: static;
								padding: 0 0 10px;
								border: none;
								background: none;
								width: 100%;
								display: flex;
								height: 100%;
								flex-direction: column;
								justify-content: flex-start;

								.menu-side-panel-header {
									display: flex;
									flex-direction: row;
									align-items: baseline;
									margin-bottom: 1.5rem;
									cursor: pointer;

									&:hover {
										opacity: 0.7;
									}

									&:hover > .go-back {
										transform: translateX(-5px);
										transition: 0.25s ease-in-out;
									}

									.go-back {
										display: flex;
										align-items: center;
										margin-right: 0.5rem;
									}

									span {
										font-size: $font-size-base * 1.5;
										font-weight: 700;
										color: $white;
									}
								}

								.nav-item {
									&:not(:last-child) a:not(.highlight) {
										border-bottom: 1px solid $white;
									}

									.nav-link {
										font-size: $font-size-base;
										font-weight: 600;
										color: $white;
										padding: 0.6rem 0;

										&:hover {
											opacity: 0.7;
										}
									}
								}
								@include media-breakpoint-up(lg) {
									justify-content: center;
								}
							}
						}
					}
				}
			}

			.logo-bestcamp {
				margin-left: auto;
				margin-right: 15px;
				width: 171px;
				height: 31px;
				@include media-breakpoint-down(md) {
					display: none;
				}
			}

			.navbar-nav.socials {
				.nav-item {
					.nav-link {
						color: $mineralgreen;
						font-size: $h3-font-size;

						&:hover {
							opacity: 0.7;
						}
					}
				}
			}

			.search-and-book-link {
				margin: 0 15px;
				@include media-breakpoint-down(sm) {
					display: none !important;
				}

				li {
					a {
						@extend .btn-highlight;
						padding: 8px 12px !important;
						font-weight: $font-weight-base !important;
						box-shadow: 6px 6px 12px $box-shadow;
					}
				}
			}

			.site_switcher {
				.navbar-nav {
					.nav-item {
						width: 21px;
						height: 21px;
						margin-right: 10px;
						overflow: hidden;
						border-radius: 100%;

						&:not(:first-of-type) {
						}

						.nav-link {
							font-size: $h4-font-size;
							line-height: 1;
							display: block;
							padding: 0;

							img {
								max-width: 100%;
								transform: scale(1.6);
							}
						}
					}
				}
			}
		}
	}

	/*  sticky
		========================================================================== */
	@include media-breakpoint-up(xl) {
		&.sticky {
			.header-main {
				.logo {
					max-width: 60px;
				}
			}
		}
	}
}
