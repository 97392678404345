.accommodation-list .accommodation-list-item .list-item-container {
	background: #5c7457;
}


.accommodation-list-item .list-item-content .list-item-container .list-item-footer .matrix-btn svg {
	background: #fff;
}


.accommodation-list-item .list-item-content .list-item-container .list-item-body .list-item-caption .list-item-description:after,
.accommodation-list-item .list-item-content .list-item-container .list-item-body .list-item-extra .list-icons:after {
	background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #5c7457) !important;
	display: none;
}

.w3-vue-booking-wrapper.w3-searchbook-widget .accommodation-list .list-item-content .list-item-container .list-item-body .list-item-description {
	padding-bottom: 0 !important;
	max-height: 100% !important;
	height: 96px !important;
}

.w3-vue-booking-wrapper.w3-searchbook-widget .accommodation-list .list-item-content .list-item-container .list-item-body .list-item-description .list-item-text > div > span {
	display: none !important;
}


.accommodation-list-item .list-item-content .list-item-container .list-item-footer .matrix-btn svg {
	color: #5c7457;
}

.accommodation-list-item .list-item-content .list-item-container .list-item-body .list-item-caption .list-item-title .list-item-link {
	font-family: Playball, cursive;
	color: #fff;
	font-weight: 400;
	white-space: nowrap;
	font-size: 25px;
}

.accommodation-list-item .list-item-content .list-item-container .list-item-body .list-item-caption .list-item-title .list-item-link:hover {
	color: #fff;
}

.w3-vue-booking-wrapper .prices-availability .price:hover {
	color: #fff;
}

.accommodation-list-item .list-item-content .list-item-container .list-item-body .list-item-caption .list-item-text .card-description-content,
.accommodation-list-item .list-item-content .list-item-container .list-item-body .list-item-extra .list-icons .label,
.accommodation-list-item .list-item-content .list-item-container .list-item-body .list-item-extra .list-icons .icon i:before,
.accommodation-list-item .list-item-content .list-item-container .list-item-body .list-item-extra .list-item-btn,
.accommodation-list-item .list-item-content .list-item-container .list-item-footer .matrix-btn {
	color: #fff;
}

.accommodation-list-item .list-item-content .list-item-container .list-item-body .list-item-caption p {
	color: #fff !important;
}

.accommodation-list-item .list-item-content.list-item-content {
	border-radius: 10px;
	overflow: hidden;
}

.w3-vue-booking-wrapper .sorting .w3-radio .input-holder .input {
	background: #1d89cd;
}

.w3-vue-booking-wrapper .sorting .toggle-list .toggle-list-item.active a,
.w3-vue-booking-wrapper .matrix-controls .btn-next,
.w3-vue-booking-wrapper .matrix-controls .btn-prev,
.w3-vue-booking-wrapper .icon-input {
	color: #1d89cd;
}

.w3-vue-booking-wrapper {
	width: 100%;
}

.w3-vue-booking-wrapper .matrix .duration {
	background: #5c7457;
	min-height: 64px;
	font-size: 0.95rem;
}

.w3-vue-booking-wrapper .matrix .available {
	background: #BACDB0;
	min-height: 64px;
}

.w3-vue-booking-wrapper .matrix .available:hover .prices .price-old,
.w3-vue-booking-wrapper .matrix .available:hover .prices .discount-price,
.w3-vue-booking-wrapper .list-item-availability .travel-info .arrival,
.w3-vue-booking-wrapper .list-item-availability .travel-info .duration {
	color: #fff;
}

.w3-vue-booking-wrapper .matrix .available .prices .price-old,
.w3-vue-booking-wrapper .matrix .available .prices .discount-price,
.w3-vue-booking-wrapper .list-item-availability .travel-info .arrival,
.w3-vue-booking-wrapper .list-item-availability .travel-info .duration {

	font-size: 0.95rem;
}

.w3-vue-booking-wrapper .prices-availability {
	background: #1d89cd;
}

.intro .w3-filter-collection .filter-element .input,
.intro .w3-filter-collection .filter-element input {
	border: 1px solid #1d89cd;
	box-shadow: none;
	color: #93B612;
	font-weight: 700;
	text-align: left;
	font-size: 14px;
	border-radius: 7px;
}

.intro .w3-filter-collection .filter-element input::placeholder,
.intro .w3-filter-collection .filter-element .input .placeholder {
	font-weight: 700;
	color: #93B612;
	text-align: left;
	font-size: 14px !important;
}

.intro .w3-filter-collection .filter-element .label {
	display: none;
}

.w3-vue-booking-wrapper {
	&.w3-minisearchbook-widget {
		.widget-element {
			.filters {
				justify-content: flex-end;
				align-items: center;
				margin: 0;
				padding-top: 20px;

				.label {
					display: none;
				}

				input,
				.input,
				input::placeholder {
					color: #93b612;
					font-weight: 700;
				}

				.widget-btn {
					padding: 7px;
					background-color: #1d89cd;
					font-size: 13px;
					min-height: 40px;
					text-align: center;

					&:hover {
						color: #fff;
					}
				}

				.filter-element {
					&.filter-context-button {
						flex: 0 0 auto;
					}
				}
			}
		}
	}
}

.w3-vue-booking-wrapper.w3-minisearchbook-widget .icon-input {
	background: transparent;
}

.w3-matrix-widget {
	.w3-filter-collection {
		.filters {
			margin-bottom: 20px;
		}
	}
}


.w3-vue-booking-wrapper .matrix .available .prices .price {
	font-size: .95rem;
}

.w3-vue-booking-wrapper .matrix .available:hover .prices .price {
	color: #fff;
}

.w3-vue-booking-wrapper .matrix .available .prices .price-old {
	opacity: 0.6;
}

.w3-vue-booking-wrapper .matrix .available .prices .discount-price {
	font-weight: bolder;
}

.w3-vue-booking-wrapper.w3-arrangement-widget .accommodation-list .list-item-content .list-item-container .list-item-body .list-item-title,
.w3-vue-booking-wrapper.w3-arrangement-widget .arrangements-list .list-item-content .list-item-container .list-item-body .list-item-title,
.w3-vue-booking-wrapper.w3-searchbook-widget .accommodation-list .list-item-content .list-item-container .list-item-body .list-item-title,
.w3-vue-booking-wrapper.w3-searchbook-widget .arrangements-list .list-item-content .list-item-container .list-item-body .list-item-title {
	height: 60px;
	max-height: 60px;
}

.w3-vue-booking-wrapper.w3-arrangement-widget .accommodation-list .list-item-content .list-item-container .list-item-body .list-item-title a,
.w3-vue-booking-wrapper.w3-arrangement-widget .arrangements-list .list-item-content .list-item-container .list-item-body .list-item-title a,
.w3-vue-booking-wrapper.w3-searchbook-widget .accommodation-list .list-item-content .list-item-container .list-item-body .list-item-title a,
.w3-vue-booking-wrapper.w3-searchbook-widget .arrangements-list .list-item-content .list-item-container .list-item-body .list-item-title a {
	white-space: normal;
}

.w3-vue-booking-wrapper .prices-availability:hover {
	.prices {
		div span {
			color: #fff !important;
		}
	}
}

.w3-vue-booking-wrapper.w3-minisearchbook-widget .widget-element .filters .input {
	text-align: left;
}

