.footer {
	position: relative;
	padding-top: 150px;
	@media(max-width: 991px) {
		padding-top: 75px;
		margin-top: 75px;
	}
	@media(max-width: 576px) {
		padding-top: 0;
		margin-top: 75px;
	}

	&::before {
		content: url('/images/swoosh01.svg');
		position: absolute;
		width: 100%;
		height: auto;
		top: 0;
		z-index: -1;
		left: 0;
		transform: translateY(-50%);
		@media(max-width: 767px) {
			z-index: 1;
		}
	}

	&:after {
		content: '';
		width: 100%;
		height: 100%;
		top: -70px;
		left: 0;
		background: #fff;
		z-index: -2;
		position: absolute;
		@media(max-width: 767px) {
			top: -20px;
		}
	}

	h2 {
		font-size: 24px;
	}

	.footer-top {
		background: $clayash;
		position: relative;
		padding: 0;
		margin-bottom: 120px;

		.footer-logo-link {
			display: hidden;

			.item {
				flex: 0 0 auto;
				width: auto;

				.card {
					background-color: transparent;
					border: none;

					.card-img {
						opacity: 0;
					}

					.card-image {
						font-size: 0;
						background-color: transparent;

						.card-img {
							height: 59px;
						}
					}

					.card-body {
						display: none !important;
					}
				}
			}
		}
	}

	.footer-details {
		padding: 0;
		background: #fff;
		@media(max-width: 991px) {
			padding-top: 50px;
		}

		h1 {
			font-family: $font-family-noto;
			font-weight: 700;
			font-size: $h3-font-size !important;
			color: $mineralgreen;
			@include media-breakpoint-up(sm) {
				font-size: $h2-font-size;
			}
		}

		p {
			font-family: $font-family-noto;
			font-size: $font-size-base_sm;
			color: $mineralgreen;
			font-weight: $font-weight-regular;

			span {
				min-width: 250px;
				display: inline-block;
			}

			@media screen and(max-width: 320px) {
				max-width: 80% !important;
			}
			@media screen and(max-width: 375px) {
				max-width: 73% !important;
			}
			@include media-breakpoint-down(sm) {
				max-width: 60%;
				span {
					min-width: 1px;
					display: block;

					a {
						margin-left: 0;
						display: block;
					}
				}
			}
		}

		a {
			color: $mineralgreen;
			font-family: $font-ibm-plex;
			text-decoration: underline;
			font-size: $font-size-base;
			font-weight: $font-weight-base;

			&:hover {
				color: darken($mineralgreen, 10%);
			}
		}

		.footer-logo {
			width: 200px;
			margin: 30px auto;
		}

		.container-default {
			.footer_text {
				p {
					margin-bottom: 0;
					font-family: $font-family-noto;
					font-weight: 700;
					max-width: 100% !important;
				}
			}
		}

		.footer_logolink {
			margin-top: 20px;

			.list {
				.list-item {
					&:hover {
						opacity: 0.7;
					}

					i {
						font-size: 55px;
					}
				}
			}
		}
	}

	.footer-socket {
		background: url("/images/footer-socket.svg") no-repeat top/cover !important;
		width: 100%;
		height: 100%;
		padding: 45px 0;
		@include media-breakpoint-down(sm) {
			background: url("/images/swoosh03.svg") no-repeat top/cover !important;
		}

		.footer_link {
			margin: 0 auto;

			.list {
				.list-item {
					margin: 0 10px;

					a {
						font-family: $font-family-noto;
						font-size: $font-size-base_sm;
						color: $white;
						font-weight: $font-weight-regular;
					}
				}
			}
		}
	}
}

&.home {
	.footer {
		padding-top: 0;

		&::before {
			content: url('/images/swoosh02.svg');
			transform: translateY(-100%);
		}

		.footer-details {
			padding-top: 0;
		}
	}
}
