.main {
	@include media-breakpoint-down(lg) {
		overflow: hidden;
	}

	.intro.content {
		// background: $clayash;
		padding: 0 0 30px 0;
		margin-top: -50px;
		position: relative;

		&::before {
			content: "";
			background-color: $clayash;
			height: 90%;
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			@include media-breakpoint-up(lg) {
				height: 75%;
			}
		}

		&::after {
			background: url("/images/light-green-top-curve.svg") no-repeat
				top/100%;
			content: "";
			position: absolute;
			width: 100%;
			height: 120px;
			bottom: 35px;
			z-index: -1;
			right: 0;
			transform: rotate(180deg);
			@media screen and(max-width: 375px) {
				bottom: 60px !important;
			}
			@include media-breakpoint-up(sm) {
				bottom: 21px;
			}
			@include media-breakpoint-up(lg) {
				bottom: 20px;
			}
		}

		.container-two-columns {
			.container-holder {
				margin: 0 -15px -0 -15px;

				.column {
					margin: 0;

					&.one {
						margin-top: 70px;
						@include media-breakpoint-up(sm) {
							margin-top: 90px;
						}

						h2 {
							font: {
								size: $font-size-base * 2.25 !important;
								family: $font-playball;
								weight: $font-weight-regular;
							}
							@include media-breakpoint-up(xl) {
								font-size: $h1-font-size !important;
							}
						}

						.page_block.link {
							font: {
								family: $font-ibm-plex;
								weight: $font-weight-base;
							}
							text-decoration: underline;
							color: $body-color;
							position: relative;
							padding-right: 20px;

							&::after {
								content: "\f30b";
								position: absolute;
								top: 50%;
								right: -10px;
								transform: translateY(-50%);
								font-family: $font-awesome;
								font-size: $h3-font-size;
								font-weight: 500;
								transition: 0.3s;
							}

							&:hover {
								&::after {
									right: -15px;
								}
							}
						}
					}

					&.two {
						box-shadow: 6px 6px 12px $box-shadow;
						border-radius: 10px;
						background: $white;
						max-width: 350px;
						padding: 50px 30px 30px 30px !important;
						text-align: center;
						z-index: 3;
						@include media-breakpoint-down(sm) {
							margin: 50px auto 0;
						}

						.aantal_sterren {
							justify-content: center;

							span {
								display: none;
							}
						}

						h2 {
							color: $secondary;
							line-height: 1;
							margin-top: 15px;
							font: {
								size: $font-size-base * 2.25;
								weight: $font-weight-base;
								family: $font-playball;
							}
						}

						.check-prijzen {
							font-size: $h4-font-size !important;
							font-weight: $font-weight-base !important;
							@extend .btn-highlight;
							margin-top: 15px;
						}
					}
				}
			}
		}
	}

	.home-section {
		margin-top: 60px;
		position: relative;
		background: $neptune;

		&::before {
			background: url("/images/facility-bottm-curve.svg") no-repeat
				top/cover;
			content: "";
			position: absolute;
			width: 100%;
			height: 200px;
			top: -95px;
			z-index: 0;
			left: 0;
			transform: rotate(180deg);
		}

		&::after {
			content: "";
			background: url("/images/repeat-grid.png") no-repeat center/cover;
			background-color: $white;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 300px;
			width: 100%;
			display: block;
		}

		.container-fluid {
			@include media-breakpoint-up(sm) {
				padding: 0 60px;
			}

			.container-holder {
				margin: 0;

				.column.one {
					padding-left: 0;
				}
			}
		}

		.grid-container {
			position: relative;
			z-index: 2;

			h1 {
				font-family: $font-family-noto;
				font-size: $h2-font-size;
				font-weight: 700;
				text-align: center;
				color: $mineralgreen;
				margin-bottom: $spacer * 2.188;
				@include media-breakpoint-down(sm) {
					font-size: $font-size-base * 1.5 !important;
				}
			}

			.home-top-facilities {
				&.grid {
					position: relative;
					flex-wrap: nowrap;
					flex-direction: row;
					max-width: 100%;
					@media (min-width: 1500px) {
						justify-content: center;
					}
					@media (max-width: 1499px) {
						justify-content: flex-start;
						overflow-x: scroll;
					}

					.item {
						flex: 0 0 230px;
						max-width: 230px;
						margin: 0 0 60px 0;
						@include media-breakpoint-up(xl) {
							flex: 0 0 270px;
							max-width: 270px;
						}
						@media (min-width: 1500px) {
							flex: 0 0 300px;
							max-width: 300px;
						}

						.card {
							background: transparent;
							border: none;
							border-radius: 0;
							text-decoration: none;

							&:hover {
								text-decoration: none;
							}

							&-image {
								background-color: transparent;

								.card-img {
									height: 230px;
									border-radius: 10px;
									object-fit: cover;
								}
							}

							&-body {
								background: transparent;

								.card-caption {
									.card-title {
										font-family: $font-family-noto;
										font-weight: 700;
										font-size: $h4-font-size;
										color: $white;
										text-align: center;
									}

									.card-description {
										min-height: auto;

										&::after {
											display: none !important;
										}

										&-content {
											font-family: $font-family-noto;
											font-size: $font-size-base_sm;
											color: $mineralgreen;
											text-align: center;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.big-img-wrapper {
			position: relative;
			@extend .container-two-columns-block;
			@include media-breakpoint-up(lg) {
				margin-top: 30px;
			}

			&::after {
				background: url("/images/facility-bottm-curve.svg") no-repeat
					bottom/100%;
				content: "";
				position: absolute;
				width: 100%;
				height: 120px;
				z-index: 2;
				right: 0;
				bottom: 270px;

				@include media-breakpoint-up(sm) {
					bottom: 235px;
				}
				@include media-breakpoint-up(lg) {
					bottom: 205px;
				}
			}

			.container-holder {
				position: relative;

				.column {
					position: relative;
					z-index: 11;

					&.one {
						@extend .block-1;
						@extend .home-block;

						.resource_image {
							border-radius: 10px 0 0 10px;
							@include media-breakpoint-up(sm) {
								border-radius: 10px 0 0 10px;
							}
							@include media-breakpoint-down(sm) {
								border-radius: 10px 10px 0 0;
							}

							.img-fluid {
								max-height: 600px !important;
							}
						}
					}

					&.two {
						@extend .block-2;
						@include media-breakpoint-down(sm) {
							padding: 40px 30px !important;
							border-radius: 0 0 10px 10px;
						}

						h1 {
							font-size: $h1-font-size !important;
							white-space: normal !important;
						}

						.link {
							margin-top: 50px;
							@extend .btn-primary;
							@include media-breakpoint-only(md) {
								margin-top: 0;
							}
						}
					}
				}
			}
		}
	}

	section[class^="home_block"] {
		background: url("/images/repeat-grid.png") no-repeat center/cover;
		height: 100%;
		width: 100%;
		padding-bottom: 30px;
	}

	.home_block_1 {
		padding: 50px 0 20px;
		@include media-breakpoint-up(sm) {
			width: 100%;
			padding: 70px 0 20px;
		}

		.container {
			.container-holder {
				cursor: pointer;

				h1 {
					font-weight: 700;
					font-size: $h2-font-size !important;
					@include media-breakpoint-down(sm) {
						font-size: $h3-font-size !important;
					}
				}
			}
		}

		.container-two-columns {
			.container-holder {
				.column {
					&.one {
					}

					&.two {
						p {
							max-width: 400px;

							a {
								font: {
									family: $font-ibm-plex;
									weight: $font-weight-base;
								}
								text-decoration: underline;
								color: $body-color;
								position: relative;
								padding-right: 20px;

								&::after {
									content: "\f30b";
									position: absolute;
									top: 50%;
									right: -10px;
									transform: translateY(-50%);
									font-family: $font-awesome;
									font-size: $h3-font-size;
									font-weight: 500;
									transition: 0.3s;
								}

								&:hover {
									&::after {
										right: -15px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.home_block_2 {
		.container-two-columns {
			position: relative;
			@extend .home-block;

			.container-holder {
				cursor: pointer;

				.column {
					position: relative;

					&.one {
						@extend .block-1;

						.resource_image {
							@include media-breakpoint-up(sm) {
								border-radius: 10px 0 0 10px;
							}
							@include media-breakpoint-down(sm) {
								border-radius: 10px 10px 0 0;
							}
						}
					}

					&.two {
						@extend .block-2;
						background-color: $nileblue;
						@include media-breakpoint-down(sm) {
							padding: 40px 50px !important;
							border-radius: 0 0 10px 10px;
						}
					}
				}
			}
		}
	}

	.home_block_3 {
		.container-two-columns {
			position: relative;
			@extend .home-block;

			.container-holder {
				cursor: pointer;

				.column {
					position: relative;

					&.one {
						@extend .block-2;
						z-index: 1;
						@include media-breakpoint-down(sm) {
							padding: 40px 50px !important;
							order: 2;
						}
						@include media-breakpoint-up(sm) {
							left: 0;
							border-radius: 10px 0 0 10px;
							text-align: right;
						}

						h1 {
							@include media-breakpoint-up(sm) {
								margin-left: 120px;
							}
						}

						@include media-breakpoint-down(sm) {
							border-radius: 0 0 10px 10px;
						}
					}

					&.two {
						@extend .block-1;

						.page_block.resource_image {
							@include media-breakpoint-up(sm) {
								border-top-right-radius: 10px;
								border-bottom-right-radius: 10px;
								overflow: hidden;
							}
							@include media-breakpoint-down(sm) {
								border-radius: 10px 10px 0 0;
							}
						}
					}
				}
			}
		}
	}

	.home_block_4 {
		.container-two-columns {
			position: relative;
			@extend .home-block;

			.container-holder {
				cursor: pointer;

				.column {
					position: relative;

					&.one {
						@extend .block-1;

						.resource_image {
							@include media-breakpoint-up(sm) {
								border-radius: 10px 0 0 10px;
							}
							@include media-breakpoint-down(sm) {
								border-radius: 10px 10px 0 0;
							}
						}
					}

					&.two {
						@extend .block-2;
						background-color: $nileblue;
						@include media-breakpoint-down(sm) {
							padding: 40px 50px !important;
						}
						@include media-breakpoint-down(sm) {
							border-radius: 0 0 10px 10px;
						}
					}
				}
			}
		}
	}

	.home_block_5 {
		.container-two-columns {
			@extend .home-block;
			position: relative;

			.container-holder {
				cursor: pointer;

				.column {
					position: relative;

					&.one {
						@extend .block-2;
						z-index: 1;
						@include media-breakpoint-down(sm) {
							padding: 40px 50px !important;
							order: 2;
						}
						@include media-breakpoint-up(sm) {
							left: 0;
							border-radius: 10px 0 0 10px;
							text-align: right;
						}

						h1 {
							@include media-breakpoint-up(sm) {
								margin-left: 200px;
							}
						}

						@include media-breakpoint-down(sm) {
							border-radius: 0 0 10px 10px;
						}
					}

					&.two {
						@extend .block-1;

						.page_block.resource_image {
							@include media-breakpoint-up(sm) {
								border-top-right-radius: 10px;
								border-bottom-right-radius: 10px;
								overflow: hidden;
							}
							@include media-breakpoint-down(sm) {
								border-radius: 10px 10px 0 0;
							}
						}
					}
				}
			}
		}
	}

	.home_block_6 {
		.container-default {
			.container-holder {
				text-align: center;

				.page_block.link {
					@extend .btn-highlight;
					max-width: 300px;
					position: relative;
					padding: 14px 20px !important;
					text-decoration: underline;

					&::after {
						content: "\f30b";
						position: absolute;
						top: 50%;
						right: 20px;
						transform: translateY(-50%);
						font-family: $font-awesome;
						font-size: $h3-font-size;
						font-weight: 500;
						transition: 0.3s;
					}

					&:hover {
						&::after {
							right: 15px;
						}
					}
				}
			}
		}
	}

	.home_block_7 {
		margin-bottom: 40px;
		padding-bottom: 110px !important;
		@include media-breakpoint-up(sm) {
			margin-bottom: 60px;
		}
		position: relative;

		.container-fluid {
			@include media-breakpoint-up(sm) {
				padding: 0 60px;
			}
		}

		.container-two-columns {
			cursor: pointer;
			@extend .container-two-columns-block;

			.container-holder {
				position: relative;
				margin: 0;

				.column {
					position: relative;
					z-index: 11;

					&.one {
						@extend .block-2;
						z-index: 12;
						background-color: $nileblue;
						@include media-breakpoint-down(sm) {
							padding: 40px 30px !important;
							border-radius: 0 0 10px 10px;
							order: 2;
						}
						@include media-breakpoint-up(sm) {
							left: 0;
							border-radius: 10px 0 0 10px;
							text-align: right;
						}

						h1 {
							font-size: $h1-font-size !important;
							white-space: normal !important;
							@include media-breakpoint-up(sm) {
								white-space: nowrap !important;
							}
							@include media-breakpoint-only(md) {
								margin-left: 20px;
							}
							@include media-breakpoint-up(lg) {
								margin-left: 50px;
							}
						}

						.link {
							@extend .btn-highlight;
							margin-top: 50px;

							&::after {
								content: "\f30b";
								font-family: $font-awesome;
								font-size: $font-size-base * 1.5;
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								width: 20px;
								height: auto;
								display: block;
								right: 25px !important;
							}
						}

						@include media-breakpoint-down(sm) {
							border-radius: 0 0 10px 10px;
						}
					}

					&.two {
						@extend .block-1;
						@extend .home-block;
						padding-right: 0;

						.resource_image {
							@include media-breakpoint-down(sm) {
								border-radius: 10px 10px 0 0;
							}
							@include media-breakpoint-up(sm) {
								border-top-right-radius: 10px;
								border-bottom-right-radius: 10px;
								overflow: hidden;
							}

							.img-fluid {
								max-height: 600px !important;
							}

							@include media-breakpoint-down(sm) {
								border-radius: 10px 10px 0 0;
							}
						}
					}
				}
			}
		}
	}

	//contact page

	.contact-wrapper {
		padding: 60px 0px 120px;
		position: relative;

		.contact-header {
			min-height: 170px;
			padding-top: 20px;
			@include media-breakpoint-up(sm) {
				min-height: 125px;
			}

			h1 {
				font-family: $font-playball;
				font-size: $h1-font-size;
				font-weight: $font-weight-regular;
			}
		}

		.contact-form {
			margin-top: 30px;

			.form {
				a {
					color: $link-1;
				}

				small {
					color: $black;
				}
			}

			form {
				.form-group {
					&:nth-child(7) {
						margin: 20px 0;
					}

					.btn-primary {
						@extend .btn-primary;
					}
				}
			}

			.form-control-label {
				font-family: $font-family-noto;
				font-size: $font-size-base;
				font-weight: 700;
			}

			.form-check-label {
				&.required {
					font-size: $font-size-base_xs;
				}
			}

			.column {
				&.one {
					h1 {
						font-family: $font-family-noto;
						font-size: $h2-font-size;
						font-weight: 700;
					}
				}

				&.two {
					@include media-breakpoint-down(sm) {
						margin-top: 30px;
					}

					h1 {
						font-family: $font-family-noto;
						font-size: $font-size-base * 1.25;
						font-weight: 700;
					}

					.email {
						color: $secondary;
						text-decoration: underline;
					}
				}
			}
		}
	}
}

&.service_category {
	.main {
		.content.overview {
			position: relative;
		}

		.content.overview-wrapper {
			padding: 100px 0 50px;
			margin-bottom: 100px;
			@include media-breakpoint-up(sm) {
				padding: 150px 0 50px;
			}

			&::before,
			&::after {
				background: none;
			}

			.container {
				&::after {
					background: none;
				}

				.info {
					h2 {
						font-size: $h3-font-size;
					}
				}

				.info-extra {
					.gallery {
						&-item {
							.gallery-link {
								.gallery-img {
									border-radius: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.gallery-grid {
	.gallery-link {
		.gallery-caption {
			display: none;
		}
	}
}

&.home {
	.section-mini-zoek-en-boek {
		position: relative;

		&::before {
			background: url(/images/light-green-bottom-curve.svg) no-repeat
				bottom/100%;
			background-size: cover;
			content: "";
			position: absolute;
			width: 100%;
			height: 170px;
			top: 0;
			z-index: -1;
			left: 0;
			@include media-breakpoint-up(sm) {
				height: 250px;
			}
		}

		h1 {
			font-family: "Playball", cursive !important;
			font-weight: 400 !important;
		}
	}
}

&:not(.vacancy_category) {
	.intro-section {
		padding-top: 19vh !important;
		&:before {
			content: "";
			background: url(/images/light-green-top-curve.svg) no-repeat
				bottom/100%;
			background-size: 100%;
			position: absolute;
			width: 100%;
			height: 250px;
			top: 3px;
			transform: translateY(-100%);
		}

		&:after {
			content: "";
			background: url(/images/light-green-top-curve.svg) no-repeat
				top/100%;
			background-size: 100%;
			position: absolute;
			width: 100%;
			height: 250px;
			top: 0;
			transform: rotate(180deg);
			@media (max-width: 767px) {
				top: unset !important;
				bottom: 0;
				background-position: bottom;
			}
		}
	}
}
&.vacancy_vacancy_detail {
	.btn-back {
		width: 100%;
		clear: both;
		display: none;
	}
	.intro-section h1.vacancy-title {
		display: unset !important;
		position: absolute;
		z-index: 990;
		top: 178px;
		max-width: 65%;
		font-size: 40px;
		@include media-breakpoint-down(md) {
			top: 100px;
		}
	}
	.intro-section {
		&::after {
			@include media-breakpoint-down(md) {
				top: unset !important;
				bottom: 0;
				height: 7vh;
				transform: scaleY(-1) translateY(-100%);
			}
		}
		@include media-breakpoint-down(md) {
			.container-holder {
				flex-flow: column-reverse !important;
				display: flex;

				.vacancy-title {
					position: relative !important;
					top: 0 !important;
					max-width: unset !important;
					padding-left: 15px;
				}
			}
		}
	}
	.content.info {
		margin-top: 12vh !important;
	}

	div.info {
		max-width: 60% !important;
		flex-basis: 60% !important;
		@include media-breakpoint-down(lg) {
			max-width: 100% !important;
			flex-basis: 100% !important;
		}
		h3 {
			margin-top: 0 !important;
		}
	}
	div.info-extra {
		max-width: 40% !important;
		flex-basis: 40% !important;
		@include media-breakpoint-down(lg) {
			max-width: 100% !important;
			flex-basis: 100% !important;

		}
	}
}
&.vacancy_category {
	.info {
		display: flex;
		flex-flow: column;
		.form-control {
			display: block !important;
			order: 2;
			max-width: 300px !important;
			height: 40px;
			border-radius: 4px;
			color: #93b612;
			margin-left: auto;
			font-weight: 700;
			z-index: 1000;
			@include media-breakpoint-down(md) {
				max-width: 100% !important;
			}
		}
		@include media-breakpoint-down(md) {
			margin-top: 1rem;
		}
	}
	.intro-section {
		display: none;
	}
	.btn-back {
		display: none;
	}
	section.eyecatcher {
		background: #72b8b4;
	}
	section.content {
		padding-top: 125px !important;
	}
	.lead-section {
		margin: 0 !important;
		padding: 6vh 0;
		margin-bottom: 16vh !important;
		background-color: #72b8b4;
		background-color: #6bb9be;
		position: relative;
		* {
			z-index: 2;
		}
		&:before {
			content: "";
			background: url(/images/light-blue-top-curve.svg) no-repeat
				bottom/100%;
			background-size: 100%;
			position: absolute;
			width: 100%;
			height: 250px;
			top: -240px;
			left: 0;
		}

		&:after {
			content: "";
			background: url(/images/light-blue-bottom-curve.svg);
			background-size: 100%;
			background-repeat: no-repeat;
			position: absolute;
			width: 105%;
			height: 300px;
			left: -5%;
			background-position: top;
			// top: 125px !important;
			bottom: 0;
			transform: translateY(40%);
			z-index: 1;

			@media (max-width: 767px) {
				top: unset !important;
				left: 0 !important;
				width: 100% !important;
				background-size: 100% !important;
				background-position: top;
				height: 7vh;
				bottom: 0;
			}
		}
		.js-description-extra {
			@include media-breakpoint-down(md) {
				.container {
					padding: 0;
				}
			}
			color: $white !important;
			h6,
			h5,
			h4,
			h3,
			h2,
			h1 {
				color: $white !important;
			}
			ul {
				list-style: none;
				padding: 0;
				li {
					padding-left: 0;
					margin-left: 0;
					margin-bottom: 0.55rem;
					display: flex;
					align-items: flex-start;
					&::before {
						content: "\f00c";
						margin-right: 0.5rem;
						font-family: $font-awesome;
						display: inline-block;
					}
				}
			}
		}
	}
	.content {
		padding-bottom: 0 !important;
		margin-bottom: 0 !important;
		@include media-breakpoint-up(lg) {
			margin-bottom: 12vh !important;
			padding-bottom: 12vh !important;
		}
	}
}

.intro-section {
	background-color: $clayash;
	position: relative;
	min-height: 152px;
	@media (max-width: 767px) {
		min-height: 52px;
	}

	h1 {
		font-family: "Playball", cursive;
		font-weight: 400;
	}
}

.eyecatcher {
	+ .intro-section {
		margin-top: 20px;
	}
}

&.bundle-overview {
	.intro-section {
		padding-top: 80px;

		&:after {
			top: calc(100% - 1px);
			background-position: bottom;
		}
	}
}

&.bundle-category,
&.bundle-detail {
	.intro-section {
		margin-top: -130px;
		@include media-breakpoint-down(md) {
			margin-top: -70px;
		}

		h1 {
			display: none;
		}
	}
}

&:not(.bundle-category),
&:not(.bundle-detail),
&:not(.home) {
	.section-mini-zoek-en-boek {
		background-color: $clayash;
		position: relative;
		min-height: 152px;
		margin-top: -135px;
		margin-bottom: 40px;

		> .container {
			z-index: 1;
			position: relative;
			top: 130px;
		}

		&:before {
			content: "";
			background: url(/images/light-green-top-curve.svg) no-repeat
				bottom/100%;
			background-size: 100%;
			position: absolute;
			width: 100%;
			height: 250px;
			top: 3px;
			transform: translateY(-100%);
		}

		&:after {
			content: "";
			background: url(/images/light-green-top-curve.svg) no-repeat
				top/100%;
			background-size: 100%;
			position: absolute;
			width: 100%;
			height: 250px;
			top: 0;
			transform: rotate(180deg);
			@media (max-width: 767px) {
				top: 98%;
				background-position: bottom;
			}
		}
	}
}

&.one_column_page,
&.two_column_page,
&.three_column_page {
	.intro-section {
		min-height: 182px;
	}

	.main {
		padding-top: 50px;
	}
}

.mini_search_book {
	@media (max-width: 991px) {
		text-align: center;
	}
}

@media (max-width: 991px) {
	&.accommodation_category_detail .accommodation_detail .info-extra .gallery {
		display: none;
	}
}

// vacancy
.vacancy-filter {
	display: none;
}

&.vacancy_vacancy_overview,
&.vacancy_category,
&.vacancy_vacancy_detail {
	&::after {
		content: "";
		background: linear-gradient(rgba(white, 0.05), rgba(white, 1));
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		z-index: -1;
	}
	.section-mini-zoek-en-boek {
		display: none;
		&::before {
			display: none !important;
		}
	}
	.lead-section {
		margin-top: 175px;
		@include media-breakpoint-down(md) {
			margin-top: 3vh;
		}
	}
	.intro-section {
		// background-color: #76b8b4;
		background: #75b9be !important;
		&::before,
		&::after {
			background: url("/images/light-blue-bottom.svg") no-repeat
				bottom/100% !important;
			pointer-events: none;
		}
		&::after {
			top: 125px;
		}
	}

	.intro-section {
		position: relative;
	}
	.vacancy-filter {
		display: block;
		z-index: 900;
		align-items: flex-end;
		display: flex;
		padding-bottom: 0;
		.form-control {
			max-width: 300px !important;
			height: 40px;
			border-radius: 4px;
			color: #93b612;
			margin-left: auto;
			font-weight: 700;
			z-index: 1000;
		}
		@include media-breakpoint-down(md) {
			align-items: center;
			.form-control {
				margin-top: 1rem;
				max-width: 100% !important;
			}
		}
	}

	.content .form-control {
		display: none;
	}
}
&.vacancy_vacancy_overview {
	.vacancy-filter {
		display: block;
		z-index: 900;
		min-height: unset !important;
		align-items: flex-end;
		display: flex;
		padding-bottom: 4vh;
	}
	.intro-section {
		padding-top: 6vh !important;
		p {
			max-width: 750px;
			position: relative !important;
			z-index: 500 !important;
		}
		&::after {
			top: unset !important;
			z-index: 100 !important;
			bottom: 0;
			transform: translateY(50%) scaleY(-1);
		}
	}
	section.content {
		margin: calc(250px + 6vh) 0 9vh 0;

		@include media-breakpoint-down(md) {
			margin: 6vh 0;
		}
	}

	.grid.vacancy_overview {
		justify-content: flex-start;
		@include media-breakpoint-down(md) {
			gap: 6vh;
		}
	}
	.card {
		display: flex !important;
		flex-flow: column !important;
		.card-image,
		.card-image-link {
			max-width: 100% !important;
			aspect-ratio: 1/1;
			img {
				height: 100%;
				object-fit: cover;
				transition: 0.35s ease;
			}
		}
		.card-image {
			@include media-breakpoint-down(md) {
				flex: unset !important;
			}
		}
		.card-body {
			width: 100%;
			padding: 0;
			flex-basis: unset !important;
			max-width: unset !important;
			background: none !important;
			.card-caption .card-title-link .card-title {
				text-align: center;
				font-family: $font-family-base !important;
				font-size: 28px !important;
				color: #475b63 !important;
				font-weight: 700 !important;
			}
		}
		.card-subtitle,
		.card-description,
		.card-buttons {
			display: none;
		}
	}
}

&.vacancy_category {
	section.content.overview {
		display: none;
	}
	section.content {
		margin: 3vh 0 12vh 0;
	}
	.content-section > p {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 3vh auto;
		&:empty {
			display: none;
		}
	}
	.grid.vacancy_category_overview {
		.item {
			padding: 0;
			padding-right: 15px;
			.card-title {
				font-size: 1.5em !important;
			}
			.card-description-content {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				line-clamp: 5;
				-webkit-box-orient: vertical;
				white-space: wrap;
				text-overflow: ellipsis !important;
			}
		}
		margin: 6vh 0;
		justify-content: flex-start;
	}
}

&.vacancy_vacancy_detail {
	.content {
		padding-top: 16vh !important;
		@include media-breakpoint-down(md) {
			margin-bottom: 0 !important;
			padding-top: 0 !important;
		}
	}
	.vacancy_detail {
		display: flex;
		flex-flow: row;
		gap: 1rem;
		@include media-breakpoint-down(md) {
			flex-flow: column-reverse;
		}
	}
	.date {
		display: none !important;
	}
}

.vacancy-speerpunten-section {
	background: $white;
	margin-bottom: 250px;
	.title {
		text-align: center;
		margin: 6vh auto;
	}

	.card {
		border: none;
		.card-body {
			background: $body-bg !important;
		}
		.card-image {
			max-width: 55px !important;
			font-size: unset !important;
			margin-bottom: 0.65rem;
			img {
				max-width: 55px;
			}
		}
		.card-subtitle,
		.card-description,
		.card-buttons {
			display: block;
		}
		.card-title {
			font-size: 21px;
		}
		.card-description {
			max-height: unset !important;
			margin-top: 1.2rem !important;
			&::after {
				display: none !important;
			}
		}
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 3vh;
	}
}
