.btn {
    display: inline-flex;
    align-items: center;
    padding: 12px 40px;
    font-size: $font-size-base_sm;
    font-family: $font-ibm-plex;
    font-weight: 700;
    border-radius: 5px;
    color: $white;
    text-decoration: none;
    position: relative;
    transition: 0.3s;

    &-highlight {
        @extend .btn;
        color: $white;
        background: $secondary;
        padding: 12px 60px 12px 40px;

        &:hover {
            color: $white;
            background: darken($secondary , 10%);
        }
    }
    &-primary {
        @extend .btn;
        color: $white;
        background: $primary;
        padding: 12px 60px 12px 40px;
        &::after {
            content: "\f30b";
            font-family: $font-awesome;
            font-size: $font-size-base * 1.5;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: auto;
            display: block;
            right: 25px !important;
        }
        &:hover {
            background: $white;
            border-color: $primary;
            color: $primary;
        }
    }
    // &-secondary {
    //     @extend .btn;
    //     color: $white;
    //     background: $secondary;

    //     &:hover {
    //         color: $secondary;
    //         background: $white;
    //         border: 1px solid $secondary;
    //     }
    // }
    // &-outline-primary {
    //     color: $primary;

    //     &:hover {
    //         background: $primary;
    //         color: $white;
    //     }
    // }
    // &-outline-secondary {
    //     color: $secondary;

    //     &:hover {
    //         background: $secondary;
    //         color: $white;
    //     }
    // }
}
.btn-back {
    background: transparent !important;
    color: $secondary !important;
    padding: 0 !important;
    &:hover {
        opacity: 0.7;
    }
}