/* bootstrap > colors
   ========================================================================== */
$clayash: #bacdb0;
$catcus: #5c7457;
$nileblue: #1a394d;
$neptune: #75b9be;
$mineralgreen: #475b63;
$body-color: $mineralgreen;

/* bootstrap > theme-colors
      ========================================================================== */
$primary: #93b612;
$secondary: #1d89cd;

/* bootstrap > font
       ========================================================================== */
@import url("https://fonts.googleapis.com/css2?family=Playball&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500&display=swap");
$font-family-noto: "Noto Sans", sans-serif;
$font-ibm-plex: "IBM Plex Mono", monospace;
$font-playball: "Playball", cursive;
$font-awesome: "Font Awesome 5 Pro";

/* bootstrap > typography
   ========================================================================== */
$font-family-base: $font-family-noto !default;
$font-weight-regular: 400;
$font-weight-base: 500;

$font-size-base: 1rem !default; //16px
$font-size-base_sm: 0.875rem !default; //14px
$font-size-base_xs: 0.813rem !default; //13px
$font-size-base_xxs: 0.75rem !default; //12px

$h1-font-size: $font-size-base * 3.75 !default;
$h2-font-size: $font-size-base * 1.875 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.125 !default;
$h5-font-size: $font-size-base_sm !default;
$h6-font-size: $font-size-base_xs !default;

html {
	font-size: 16px !important;
}

body.default {
	overflow-x: hidden;
}

body.accommodation_landing_page_with_code {
	.search_book {
		.filters {
			.filter-element:first-child {
				display: none;
			}
		}
	}
}
